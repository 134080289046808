  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-4-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert Bâtiment et Construction Aix-en-Provence et environs</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                

<h1>Expert Bâtiment et Construction Aix-en-Provence et environs</h1>
<p>Cabinet RV Expertises - Expert Bâtiment et Construction - Expertise Général d’Ouvrage</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert Bâtiment et Construction Aix-en-Provence et environs" />
</div>

<h2>Trouver un expert bâtiment à Aix-en-Provence et dans ses environs</h2>

<p>Le cabinet RV Expertises intervient pour toute demande de conseils techniques et besoins d’avis technique, selon votre besoin :</p>

<ul><li>avis technique sur bâtiment sinistré,</li>
<li>avis technique avant-achat ou avant-vente,</li>
<li>avis technique pour fissures sur bâtiment.</li></ul>

<h2>Quand avoir recours à un expert bâtiment ?</h2>

<p>L’expert bâtiment est un professionnel indépendant du bâtiment, certifié dont les analyses, contenues dans un rapport, sont opposables auprès des tribunaux. </p>
<p>Il engage sa responsabilité sur ses analyses techniques.</p>

<p>Vous pouvez avoir recours à un expert lorsque :</p>
<ul><li>vous sollicitez un avis technique suite à un sinistre (fissures, humidité),</li>
<li>avez besoin d’un avis technique avant l’acquisition d’un bien ou la vente d’une maison,</li>
<li>vous constatez des malfaçons sur votre chantier,</li>
<li>vous êtes en litige avec votre constructeur ou votre assurance et vous avez besoin d’un recours amiable.</li></ul>

<h2>Secteur d’intervention (villes principales et alentours):</h2>

<ul><li>Aix-en-Provence,</li>
<li>Venelles, Eguilles, Meyrargues, Peyrolles,</li>
<li>Pertuis, Cadenet, Lourmarin, Ansouis</li>
<li>Lambesc, Rognes, Le Puy-ste-Réparade,</li>
<li>Gardanne, Trets, Fuveau, Luynes, Bouc-bel-Air.</li></ul>
<p> </p>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-batiment-proche-aix-en-provence/' className='active'>Expert Bâtiment et Construction Aix-en-Provence et environs</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details